.Reserve-gray-box {
  padding: 20px;
  background: #e9e9e9;
  border: none;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 20px;
}

.Reserve-padder {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.Res-btn {
  text-decoration: none;
  color: white;
  background-color: #ddb989;
  border: none;
  border-radius: 10px;
  padding: 12px 19px 10px 19px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
}

.Res-btn:hover {
  background-color: #a08159;
  cursor: pointer;
  transition: 0.3s;
}

.Reserve-span {
  font-size: 40px;
}

.Reserve-hidden {
  display: hidden;
}

.Reserve-stop-wrapper {
  display: grid;
  grid-template-columns: 10fr 56px 56px;
  gap: 5px;
}

.Res-stop-btn {
  background-color: #ddb989;
  border-radius: 3px;
  border-width: 1px;
  align-items: center;
  text-align: center;
  transition: 0.3s;
  height: 56px;
}

.Res-divider {
  margin: 16px 0px 36px 0px;
}

.Res-reserve-btn {
  padding-top: 20px;
  width: 100%;
  display: grid;
  place-items: center;
}

.Res-modal-item {
  margin-top: 40px;
  padding: 20px 0px;
  border-top: solid 1px #b6b6b6;
  border-bottom: solid 1px #b6b6b6;
}

.Res-modal-item p {
  display: flex;
  justify-content: space-between;
}

.Res-modal-item h5 {
  padding: 16px 0px;
}

.Res-total-price {
  font-weight: bold;
}

.Reserve-info-ul li {
  list-style: disc !important;
  padding-bottom: 5px;
}

.Res-special-checks {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .Reserve-padder {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .Res-special-checks {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 575px) {
  .Reserve-info-box {
    padding-top: 30px;
  }

  .Reserve-hidden {
    display: none;
    padding: 0px;
    margin: 0px;
  }

  .Reserve-width-building {
    max-width: 1000px;
  }

  .Reserve-span {
    font-size: 25px;
  }

  .Res-special-checks {
    grid-template-columns: 1fr;
  }
}

/******************* MAP ********************/

.Reserve-map-box {
  height: 500px;
  width: 100%;
  border: none;
  border-radius: 3px;
}
