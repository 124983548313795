.Gallery-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0px 9px 0px 9px;
}

.Gallery-column {
  -ms-flex: 25%;
  flex: 25%;
  padding: 0 4px;
}

.Gallery-column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

.Gallery-our-gallery {
  display: grid;
  place-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .Gallery-column {
    -ms-flex: 50% !important;
    flex: 50% !important;
  }
}

@media only screen and (max-width: 575px) {
  .Gallery-column {
    -ms-flex: 100% !important;
    flex: 100% !important;
  }
}
