.Wb-welcome {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(100vh - 276px);
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  position: relative;
}

.Wb-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 9;
}

.Wb-welcome-msg-box {
  height: 100%;
  text-align: center;
  position: relative;
  z-index: 99;
}

.Wb-welcome-msg {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px; 
  padding: 0px 0px 30px 0px; 
  text-align: center;
}

.Wb-welcome-mainmsg {
  font-size: 60px;
  color: white;
  font-weight: 900;
  text-shadow: 
  2px 2px 4px rgba(0, 0, 0, 0.7),
  0 0 10px rgba(0, 0, 0, 0.5);
}

.Wb-welcome-submsg span {
  color: white;
  font-size: 40px;
  font-weight: 800;
  text-shadow: 
  2px 2px 4px rgba(0, 0, 0, 0.7),
  0 0 10px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 1199px) {
  .Wb-welcome {
      height: 585px;
  }

  .Wb-welcome-submsg span {
      font-size: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .Wb-welcome {
      height: 485px;
  }

  .Wb-welcome-mainmsg {
      font-size: 50px;
  }

  .Wb-welcome-submsg span {
      font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .Wb-welcome-mainmsg {
      font-size: 44px;
  }

  .Wb-welcome {
      height: 405px;
  }

  .Wb-welcome-submsg span {
      font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .Wb-welcome-mainmsg {
      font-size: 35px;
  }
}

@media only screen and (max-width: 424px) {
  .Wb-welcome-submsg span {
      font-size: 10px;
  }
}