.Boxs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
}

.Boxs-option {
  width: 150px !important;
  padding: 10px 10px 0px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  background-color: #ffffff;
}

.Boxs-option img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.Boxs-boxSpan {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.Boxs-selected {
  border: solid 2px #000000;
}
