.Service-divider {
  margin: 0px;
}

.Service-feature {
  padding: 30px 0px;
}

.Service-feature-img {
  width: 100%;
  padding-top: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.Service-margin-adder {
  margin-bottom: 10px !important;
}

.Service-margin-adder span {
  color: #DDB989;
  margin-right: 5px;
}

.Service-line-height {
  line-height: 1.8 !important;
}