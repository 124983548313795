.Journey-divider {
  margin: 0px;
}

.Journey-feature {
  padding: 30px 0px;
}

.Journey-feature-img img {
  width: 100%; 
  max-height:281px; 
  object-fit: cover;
}