.Modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 90vw;
  max-height: 85vh;
  background-color: white;
  overflow-y: auto;
}

.Modal-container {
  padding: 30px;
  width: 100%;
  height: 100%;
}

.Modal-title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bolder;
}

.Modal-btnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.Modal-children {
  padding: 10px;
}
