
.ImageSlider-container {
  padding: 50px 0px;
}

.ImageSlider-wrapper {
  height: 550px;
  width: 100%;
}

.ImageSlider-main-box {
  width: 100%;
  height: 80%;
}

.ImageSlider-main-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ImageSlider-sub-wrapper {
  height: 20%;
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 10px;
  white-space: nowrap;
}

.ImageSlider-sub-box {
  height: 100% !important;
  padding: 10px 0px;
}

.ImageSlider-sub-img {
  min-width: 110px;
  height: 100% !important;
  object-fit: cover;
  cursor: pointer;
}

.ImageSlider-active {
  opacity: 60%;
}

@media only screen and (max-width: 1200px) {
  .ImageSlider-wrapper {
      height: 350px;
  }

  .ImageSlider-main-box {
    height: 70%;
  }
  
  .ImageSlider-sub-wrapper {
    height: 30%;
  }
}

@media only screen and (max-width: 1192px) {
  .ImageSlider-wrapper {
      height: 450px;
  }

  .ImageSlider-main-box {
    height: 75%;
  }
  
  .ImageSlider-sub-wrapper {
    height: 25%;
  }
}

@media only screen and (max-width: 991px) {
  .ImageSlider-wrapper {
      height: 550px;
  }

  .ImageSlider-main-box {
    height: 80%;
  }
  
  .ImageSlider-sub-wrapper {
    height: 20%;
  }

  .ImageSlider-info {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 552px) {
  .ImageSlider-wrapper {
    height: 450px;
  }

  .ImageSlider-main-box {
    height: 75%;
  }
  
  .ImageSlider-sub-wrapper {
    height: 25%;
  }
}

@media only screen and (max-width: 424px) {
  .ImageSlider-wrapper {
    height: 350px;
  }

  .ImageSlider-main-box {
    height: 70%;
  }
  
  .ImageSlider-sub-wrapper {
    height: 30%;
  }
}