.Square-button {
  background-color: #DDB989;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px 5px 5px 0px;
  height: 46px;
  width: 46px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s;
  display: grid;
  place-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Square-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent 20%,
    rgba(255, 255, 255, 0.3) 50%,
    transparent 80%
  );
  transition: transform 0.5s;
  z-index: 1;
}

.Square-button:hover::before {
  transform: translate(100%, 0%);
}

.Square-button:hover {
  background-color: rgba(201, 168, 125, 0.8);
}