.Trip-container {
  padding-bottom: 20px;
}

.Trip-wrapper {
  width: 100%;
  position: relative;
  display: flex;
}

.Trip-btn-box {
  position: absolute;
  right: 0px;
  display: flex;
}

.Trip-head {
  display: flex;
  justify-content: space-between;
}

.pac-container {
  z-index: 9999;
}
