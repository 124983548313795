* {
  font-family: Helvetica, sans-serif;
  list-style: none;
}

.Idx-to-animate {
  opacity: 0;
}

.Idx-up-animate {
  animation: upAnimate 1s ease-in-out 0s 1 normal forwards;
  transition: opacity 500ms;
}

.Idx-right-animate {
  animation: rightAnimate 1s ease-in-out 0s 1 normal forwards;
  transition: opacity 500ms;
}

.Idx-left-animate {
  animation: leftAnimate 1s ease-in-out 0s 1 normal forwards;
  transition: opacity 500ms;
}

@keyframes upAnimate {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rightAnimate {
  0% {
    opacity: 0;
    transform: translateX(-250px);
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes leftAnimate {
  0% {
    opacity: 0;
    transform: translateX(250px);
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}