.Terms-divider {
  margin: 0px;
}

.Terms-feature {
  padding: 30px 0px;
}

.Terms-decorator li {
  list-style: disc !important;
}