.Rate-up-select {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}

.Rate-up-select > div {
  padding: 10px;
  border: 1px solid #dfc4a2;
  border-bottom: 0px solid #ddb989;
  cursor: pointer;
  font-weight: bold;
}

.Rate-bot-box {
  padding: 20px 20px 0px 20px;
  background-color: #e9dac7;
  border: 1px solid #dfc4a2;
  margin-bottom: 40px;
}

.Rate-special-checks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  margin-bottom: 20px;
}

.Rate-box-select {
  margin-bottom: 20px;
}

.Rate-calc-box {
  background-color: #e9dac7;
  border: 1px solid #dfc4a2;
  padding: 20px;
  text-align: center;
}

.Rate-price-box h5 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.Rate-price-box p {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Rate-price-box p span:first-child {
  text-align: left;
}

.Rate-price-box p span:last-child {
  text-align: right;
}

.Rate-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
