.Home-fact {
  padding: 50px 12px;
}

.Home-fact-text {
  padding: 0px 50px 0px 0px;
}

.Home-fact-text h1 {
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: 900;
}

.Home-fact-text p {
  margin-bottom: 20px;
  font-size: 20px;
}

.Home-fact-text h5 {
  font-weight: 900;
}

.Home-fact-img {
  padding: 0px;
}

.Home-img-fluid {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.Home-slider-item {
  height: 700px;
}

.Home-slider-item h1 {
  font-weight: 900;
}

.Home-slider-info {
  font-size: 20px;
  font-weight: 600;
}

.Home-our-services {
  text-align: center;
  padding: 30px 0px 22px 0px;
}

.Home-service-item {
  height: 440px;
  padding: 12px;
}

.Home-service-item-child {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  position: relative;
  text-align: center;
}

.Service-overlay-black {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background-color: black;
  opacity: 80%;
  transition: 0.3s;
  padding: 30px;
}

.Service-overlay-black:hover {
  opacity: 90%;
  transition: 0.3s;
}

.Service-overlay-gold {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background-color: #ddb989;
  opacity: 80%;
  transition: 0.3s;
  padding: 30px;
  color: black;
}

.Service-overlay-gold:hover {
  opacity: 90%;
  transition: 0.3s;
}

.Service-title {
  padding: 20px 0px 40px 0px;
}

.Service-title h4 {
  font-weight: 700;
}

.Service-info {
  font-weight: 600 !important;
}

.Service-btn {
  position: absolute;
  bottom: 30px;
  left: 0%;
  right: 0%;
}

.Service-btn p {
  margin: 0px;
}

.Home-social-icons {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-bottom: 50px;
}

.Home-social-icons a {
  text-decoration: none;
}

.Home-foot-icon {
  font-size: 30px;
}

.Home-rate-m {
  padding: 50px 0px;
  background-color: #ddb989;
}

.Home-rate-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home-rate-wrap {
  display: flex;
  gap: 50px;
  justify-content: center;
}

.Home-rate-img {
  height: 200px;
  object-fit: cover;
}

.Home-rate-text h1 {
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: 900;
}

.Home-rate-text p {
  margin-bottom: 20px;
  font-size: 20px;
}

@media only screen and (max-width: 1199px) {
  .Home-fact-text h1 {
    font-size: 30px;
  }

  .Home-fact-text p {
    font-size: 18px;
  }

  .Home-fact-text h5 {
    font-size: 20px;
  }

  .Home-rate-text h1 {
    font-size: 30px;
  }

  .Home-rate-text p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .Home-fact-text {
    padding: 0px 0px 20px 0px;
    text-align: center;
  }

  .Home-rate-text {
    text-align: center;
  }

  .Home-rate-img-box {
    text-align: center;
  }

  .Home-rate-wrap {
    flex-direction: column;
    gap: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .Home-slider-item {
    height: 500px;
  }
}

@media only screen and (max-width: 575px) {
  .Home-fact-text h1 {
    font-size: 24px;
  }

  .Home-fact-text p {
    font-size: 15px;
  }

  .Home-fact-text h5 {
    font-size: 16px;
  }

  .Home-slider-info {
    font-size: 15px;
    font-weight: 500;
  }

  .Service-info {
    font-weight: 500 !important;
  }
}

@media only screen and (max-width: 424px) {
  .Home-fact-text h5 {
    font-size: 14px;
  }

  .Home-slider-item h1 {
    font-size: 25px;
  }
}
