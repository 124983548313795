.Nav-bar {
  background-color: #232323;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 10px 6px;
}

.Nav-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 30px;
}

.Nav-btn-container {
  display: flex;
  gap: 20px;
}

.Nav-big-logo-box {
  background-color: #232323;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Nav-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Nav-bar-menu.collapsing {
  text-align: center;
}

.Nav-bar-menu.collapse {
  text-align: center;
  justify-content: center;
}

.nav-link {
  padding: 0px;
  border-radius: 10px;
  color: white !important;
  cursor: pointer;
  transition: 0.3s;
}

.nav-link:hover {
  color: #DDB989 !important;
  transition: 0.3s;
}

.navbar-brand {
  width: 175px;
  padding: 0px;
  margin: 0px;
}

.Nav-right-box {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.Nav-lang-btn {
  color: white;
  background: none;
  border: none;
  transition: 0.3s;
}

.Nav-lang-btn:hover {
  color: #DDB989;
  transition: 0.3s;
}

.nav-item {
  margin: 5px 0px;
  padding: 0px 25px;
  border-radius: 10px;
  font-size: 16px !important;
}

.dropdown-menu.show {
  background-color: #1a1a1a;
  border-radius: 10px;
  opacity: 100% !important;
  text-align: center;
}

.dropdown-item {
  color:white !important;
}

.dropdown-item:hover {
  color: black !important;
}

.Nav-disp-1 {
  display: none;
}

.Nav-disp-2 {
  display: flex;
}

@media only screen and (max-width: 991px) {

  .Nav-disp-1 {
    display: block;
  }

  .Nav-disp-2 {
    display: none;
  }

  .nav-link:hover {
      color: #DDB989 !important;
      background-color: #1a1a1a !important;
      opacity: 90% !important;
      transition: 0.3s;
  }
}

@media only screen and (min-width: 991px) {

  .Nav-reserve-menu-btn {
      display: none;
      padding: 0px;
  }

}

@media only screen and (max-width: 767px) {

  .nav-link {
      font-size: 16px !important;
  }

}