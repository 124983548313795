#footer-id a {
  text-decoration: none;
  color: #808080;
  transition: 0.3s;
}

#footer-id a:hover {
  color: #DDB989;
  transition: 0.3s;
}

#footer-id {
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #232323;
  padding: 0px;
}

.Foot-low-box {
  height: 50px;
  background-color: #151515;
  position: absolute;
  bottom: 0px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  padding: 0px;
  position: relative;
  font-weight: bold;
}

.Foot-item {
  padding: 30px 30px;
}

.Foot-item ul {
  margin: 0px;
  padding: 0px;
}

.Foot-item ul li {
  padding-top: 10px;
}

.Foot-title-box {
  color: white;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.Foot-menu-box ul li {
  color: #808080;
}
.Foot-border-up {
  display: none;
  margin: 0px;
  padding: 0px;
}

.Foot-border-down {
  display: none;
  margin: 0px;
  padding: 0px;
}

.Foot-social-icons {
  display: flex;
  justify-content: start;
  gap: 10px;
}

.Foot-social-icons a {
  text-decoration: none;
}

.Foot-icon {
  font-size: 30px;
}

@media only screen and (max-width: 1399px) {
  .Foot-social-icons {
    justify-content: center;
  }

  .Foot-item {
      text-align: center;
  }

  .Foot-border-up {
      display: block;
      color: #DDB989;
  }
}


@media only screen and (max-width: 575px) {

  .Foot-low-box {
      font-size: 10px;
  }

  .Foot-border-down {
      display: block;
      color: #DDB989;
  }
}