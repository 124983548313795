.Rect-button {
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
  transition: background-color 0.3s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Rect-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent 20%,
    rgba(255, 255, 255, 0.3) 50%,
    transparent 80%
  );
  transition: transform 0.5s;
  z-index: 1;
}

.Rect-button:hover::before {
  transform: translate(100%, 0%);
}

.Rect-primary-button {
  background-color: #DDB989;
}

.Rect-primary-button:hover {
  background-color: rgba(201, 168, 125, 0.8);
}

.Rect-secondary-button {
  background-color: #000000;
}

.Rect-secondary-button:hover {
  background-color: rgb(49, 49, 49);
}

.Rect-button-child-wrapper {
  position: relative;
}

.Rect-button-children {
  visibility: hidden;
}

.Rect-button-progress {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}